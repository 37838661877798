<template>
  <li class="flix-html-li">
      <span v-if="(( !saved && show[0] < show[1]))">
        <span class="flix-badge"><slot name="badge"><flixIcon :id="'ok'" /></slot></span>
        <slot name="title"></slot>
      </span>
      <a href="#" class="flix-html-a" @click.prevent="setClick()" :class="{'flix-text-success': show[0] != show[1], 'flix-text-warning': show[0] == show[1]}" v-else>
        <span class="flix-badge">
          <slot name="badge" v-if="show[0] < show[1] + 1"></slot>
          <flixIcon :id="'ok'" v-else/>
        </span>
        <slot name="title"></slot>
      </a>
  </li>
</template>
<script>
export default {
  components: {},
  props: { show: Array, callback: Function, saved: Boolean },
  data () {
    return {

    }
  },
  methods: {
    setClick () {
      this.callback(this.show[1])
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>
  .flix-html-li
    font-size: 10pt

    .flix-badge
      display: inline-block
      margin-right: 4px
      padding: 3px
      font-size: 10pt
      height: 20px
      width: 20px

    .flix-active
      font-weight: bold

  @media (max-width: 500px)
    .flix-breadcrumb
      > .flix-html-li
        display: block
        padding: 2px 0
        font-size: inherit

        + .flix-html-li:before
          content: ""
          padding: 0

  </style>
